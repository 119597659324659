<template>
  <v-dialog
    :max-width="$dialog.content"
    v-model="dialog"
    no-click-animation
    persistent
  >
    <close-btn @click="closeDialog()" overflow />

    <v-card>
      <v-card-title class="font-weight-bold secondary--text heading-font">Company Profiles</v-card-title>
      <v-card-text>
        <preloader v-if="getting" />

        <v-alert v-if="!getting && !profiles.length" type="info" border="left" class="mb-0" text>
          No company profiles
        </v-alert>

        <v-card outlined class="box-shadow">
          <v-list v-if="!getting && profiles.length" dense>
            <v-list-item
              v-for="profile in profiles"
              :key="profile.key"
              :to="{ name: 'BrandProfile', params: { id: profile.id } }"
              target="_blank"
            >
              <v-list-item-title>{{ profile.name }}</v-list-item-title>
              <v-list-item-icon>
                <v-icon small>mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import db from '@/firebase/init'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      profiles: [],
      dialog: false,
      getting: true,
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    getBrandProfiles(userid) {
      this.dialog = true

      db.collection('brand_profiles')
      .where('user', '==', userid)
      .get()
      .then((snapshot) => {
        if (snapshot.size) {
          snapshot.forEach(doc => {
            let profile = doc.data()
            profile.id = doc.id
            profile.ref = doc.ref
            this.profiles.push(profile)
          })
        }
        
        this.getting =  false
      })
      .catch(error => {
        this.getting = false
        console.log(error.message)
      })
    },

    closeDialog() {
      this.dialog = false
      this.profiles = []
      this.getting = true
    }
  }

}
</script>