<template>
  <div>
    <AdminTitle title="Users">
      <template v-slot:center>
        <v-sheet max-width="600" width="100%">
          <v-text-field
            @click:clear="$store.commit('users/clearHits')"
            placeholder="Search user by name or email"
            class="field-shadow"
            v-model="keyword"
            @input="search()"
            hide-details
            clearable
            outlined
            rounded
          >
            <template v-slot:append>
              <v-progress-circular v-if="status.searching" size="25" color="primary" width="2" indeterminate />
              <v-icon v-else>mdi-account-search</v-icon>
            </template>
          </v-text-field>
        </v-sheet>
      </template>
      <template v-slot:actions>
        <div class="d-flex gap-12 align-center">
          <v-btn
            @click="getUsers()"
            :loading="status.getting"
            icon
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>

          <v-btn
            @click="showInvite()"
            color="white primary--text"
            small
          >
            Invite Users
          </v-btn>
        </div>
      </template>
    </AdminTitle>

    <div>
      <!-- FILTERS -->
      <div class="d-flex justify-space-between align-center mb-5">
        <div>
          Showing <span class="font-weight-bold">{{ users.length }} users</span> 
        </div>

        <div class="d-flex gap-12 align-center">

          <v-sheet class="d-none" max-width="250">
            <v-select
              @change="getUsers()"
              v-model="status.displayRange"
              :items="displayRanges"
              item-text="name"
              item-value="value"
              prefix="Display: "
              hide-details
              class="dashboard-select field-shadow"
              outlined
              dense
            ></v-select>
          </v-sheet>

          <v-sheet max-width="250">
            <v-select
              v-model="order"
              :items="orderBy"
              item-text="name"
              item-value="value"
              prefix="Sort by: "
              hide-details
              class="dashboard-select field-shadow"
              outlined
              dense
            ></v-select>
          </v-sheet>

          <v-btn-toggle
            class="field-shadow"
            mandatory
          >
            <v-btn small icon
            @click="changeView('list')"
            >
              <v-icon small>mdi-format-list-bulleted-square</v-icon>
            </v-btn>
            <v-btn small icon
            @click="changeView('grid')"
            >
              <v-icon small>mdi-view-grid</v-icon>
            </v-btn>
          </v-btn-toggle>
        </div>
      </div>

      <template v-if="(!status.firstLoad && status.getting) || status.searching">
        <preloader message="Fetching users..." />
      </template>

      <!-- CONTENT -->
      <v-card 
        v-if="(!status.getting || status.firstLoad) && !status.searching" 
        class="box-shadow mb-5" 
        outlined
      >
        <template>
          <v-alert v-if="!users.length && !status.getting" type="info" border="left" class="mb-0">No users found.</v-alert>
          <v-simple-table v-if="users.length" v-show="this.listView == 'list'">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>User</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th class="text-center">Employee</th>
                  <th class="text-center">Account Created</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="user in ordered" :key="user.id">
                  <td width="30%">
                    <div class="d-flex align-center">
                      <UserPhoto :size="30" photoSize="thumb" :id="user.userid" class="mr-3" rounded />
                      <div>
                        <div>{{ user.fullName }}</div>
                        <div v-if="user.employeeRole && user.organization" class="caption font-weight-medium grey--text mt-n1">{{ user.employeeRole }}</div>
                      </div>
                    </div>

                  </td>
                  <td><a :href="`mailto: ${user.email}`" class="text-decoration-none">{{ user.email }}</a></td>
                  <td>
                    <v-chip label x-small :color="getLabelColor(user.role)" >{{ user.role }}</v-chip>
                  </td>
                  <td class="text-center">
                    <v-icon small :color="user.organization == ''? 'default' : 'success'">mdi-account-circle-outline</v-icon>
                  </td>
                  <td class="text-caption text-center grey--text">{{ user.createdAt | fromNow }} ago</td>
                  <td width="15" class="text-right">
                    <v-menu bottom left offset-y transition="slide-x-transition">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          v-on="on"
                          small
                        >
                          <v-icon small>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item @click="showAddEditDialog(user)" class="subtle" dense>
                          <v-list-item-title class="primary--text"><v-icon left small>mdi-account-edit-outline</v-icon> Edit</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="viewBrandProfile(user.userid)" class="subtle" dense>
                          <v-list-item-title class="primary--text"><v-icon left small>mdi-palette-swatch-variant</v-icon> Company Profiles</v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item @click="deleteUser(user)" class="subtle" dense>
                          <v-list-item-title><v-icon color="red" left small>mdi-delete-outline</v-icon> <span class="red--text">Delete</span> </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

        </template>
      </v-card>

      <div v-if="!status.getting">
        <template v-if="users.length">
          <v-row v-show="this.listView == 'grid'">
            <v-col cols="3" v-for="user in ordered" :key="user.id">
              <v-card outlined hover>
                <v-card-text class="fill-height align-center">
                  <div>
                    <div class="d-flex justify-space-between">
                      <div>
                        <v-badge
                          :value="user.organization"
                          title="Organization"
                          color="success"
                          offset-x="17"
                          offset-y="17"
                          bordered
                          overlap
                          bottom
                          dot
                        >
                          <UserPhoto :size="90" photoSize="thumb" :id="user.userid" />
                        </v-badge>
                      </div>
                      <v-chip label x-small :color="getLabelColor(user.role)" >{{ user.role }}</v-chip>
                    </div>
                      <div class="d-flex justify-space-between">
                        <h5 class="body-2 font-weight-bold pt-3">{{ user.fullName }}</h5>
                      </div>
                      <div class="d-flex justify-space-between">
                        <a :href="`mailto: ${user.email}`" class="text-decoration-none">{{ user.email }}</a>
                      </div>
                  </div>
                </v-card-text>
                <v-card-actions class="grey lighten-4 align-center">
                  <div><v-icon left small>mdi-calendar-month-outline</v-icon> <span class="caption">{{ user.createdAt | fromNow }} ago</span></div>
                  <v-spacer></v-spacer>
                  <div>
                    <v-menu bottom right offset-y transition="slide-x-transition">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          v-on="on"
                          small
                        >
                          <v-icon small>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item @click="showAddEditDialog(user)" class="subtle" dense>
                          <v-list-item-title class="primary--text"><v-icon left small>mdi-account-edit-outline</v-icon> Edit</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="subtle" dense>
                          <v-list-item-title class="primary--text"><v-icon left small>mdi-account-edit-outline</v-icon> Company Profiles</v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item @click="deleteUser(user)" class="subtle" dense>
                          <v-list-item-title><v-icon color="red" left small>mdi-delete-outline</v-icon> <span class="red--text">Delete</span> </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </div>

      <div class="text-center mt-4">
        <v-btn 
          @click="getUsers()" 
          :loading="status.getting"
          color="primary" 
          small
        >Load More</v-btn>
      </div>
    </div>

    <!-- ADD EDIT DIALOG -->
    <AddEditDialog />

    <!-- CONFIRM PASSWORD -->
    <confirm-password />

    <!-- CONFIRM DELETE -->
    <confirm-delete
      :show="confirm"
      :message="deleteMessage"
      :deleting="status.deleting" 
      @cancel="confirm = false"
      @confirmed="deleteConfirmed()"
      textConfirmation="User"
      captcha
    />

    <!-- INVITE DIALOG -->
    <invite-dialog :show="invite" @close="invite = false" />

    <!-- USER PROJECTS -->
    <user-projects :show="showProjects" :user="user" @close="toggleShowProjects(false)" />

    <!-- COMPANY PROFILES -->
    <brand-profiles-dialog ref="brandProfileDialog" />
  </div>
</template>

<script>
import { orderBy, debounce } from 'lodash'
import { mapState, mapActions } from 'vuex'

import AddEditDialog from '@/components/AddEditUser'
import InviteDialog from './components/InviteDialog'
import ConfirmPassword from '@/components/ConfirmPassword'
import UserProjects from '@/components/UserProjectsDialog'
import BrandProfilesDialog from '../brand_profiles/components/BrandProfilesDialog'

// import firebase from 'firebase'
import db from '@/firebase/init'

export default {
  name: 'Users',

  metaInfo: {
    title: 'Users'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      user: null,
      invite: false,
      keyword: null,
      confirm: false,
      toDelete: null,
      listView: 'list',
      order: 'createdAt',
      deleteMessage: null,
      showProjects: false,
      displayRanges: [
        {
          name: 'Last 60 Days',
          value: 60
        },
        {
          name: 'Last 30 Days',
          value: 30
        },
        {
          name: 'Last 7 Days',
          value: 7
        },
        {
          name: 'All',
          value: 0
        },
      ],

      orderBy: [
        {
          name: 'Date Created',
          value: 'createdAt'
        },
        {
          name: 'Name',
          value: 'fullName'
        }
      ]
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    InviteDialog,
    UserProjects,
    AddEditDialog,
    ConfirmPassword,
    BrandProfilesDialog,
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      hits: state => state.users.hits,
      users: state => state.users.users,
      status: state => state.users.status,
    }),

    ordered: function () {
      let orderType = this.order == 'createdAt' ? 'desc' : 'asc'
      let users = this.hits.length ? this.hits : this.users

      return orderBy(users, this.order, orderType)
    }

  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('users', [
      'searchUser',
      'getUsers',
      'delete',
    ]),

    getLabelColor(role) {
      if (role == 'admin') return 'success'
      if (role == 'user') return 'default'
      if (role == 'client') return 'light-blue white--text'
    },

    showAddEditDialog(user = null) {
      this.$store.commit('users/setAddDialog', true)

      if (user) {
        this.$store.commit('users/setData', user)
      }
    },

    deleteUser(user) {
      if (!this.$store.state.user.status.accountConfirmed && this.$store.getters['user/provider'] == 'Email and Password') {
        this.$store.commit('user/setShowConfirmPassword', true)
      }
      else if (this.$store.getters['user/provider'] == 'Google') {
        this.confirm = true
        this.toDelete = user
        this.deleteMessage = `Are you sure you want to delete user <strong>${user.fullName}</strong>?`
      }
      else {
        this.delete(user)
      }
    },

    deleteConfirmed() {
      Promise.resolve(this.delete(this.toDelete))
      .then(() => {
        this.confirm = false
      })
    },

    changeView( active ) {
      this.listView = active
    },

    showInvite() {
      this.invite = true
    },

    toggleShowProjects(bol, user = null) {
      if (bol) {
        this.user = user
        this.showProjects = true
      }
      else {
        this.user = null
        this.showProjects = false
      }
    },

    search: debounce(function () {
      if (this.keyword && this.keyword.length > 2) {
        this.searchUser(this.keyword)
      }
    }, 1000),

    viewBrandProfile(userid) {
      this.$refs.brandProfileDialog.getBrandProfiles(userid)
    },

    async emailTesting() {
        const message = {
            to: 'larynill@gmail.com',
            template: {
              name: "notification",
              data: {
                subject: 'Test Email',
                message: `
                  Hi,<br><br>
                  Test
                `,
              },
            },
          }

          await db.collection('mail')
          .add(message)
          .then((docRef) => 
            console.log('Queued email for delivery!', docRef.id)
          );
    },
  },

  /*------------------------------------------------------------------------------
   * CREATED
   *----------------------------------------------------------------------------*/
  created() {
    // this.emailTesting()
    if (!this.status.firstLoad) this.getUsers()
    this.$store.commit('users/clearHits')
  }

}

</script>
