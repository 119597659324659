<template>
  <div class="text-center pa-7">
    <v-card flat :class="`mx-auto text-center pa-${ padding || '5' }`" :color="color || 'white'" light>
      <v-sheet max-width="150" class="mx-auto" color="transparent">
        <span class="caption mt-n2" :class="`${textColor}--text || 'primary--text'`">{{ message || 'Please wait...' }}</span>
        <v-progress-linear
          color="accent"
          indeterminate
          rounded
          height="3"
        ></v-progress-linear>
      </v-sheet>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'Preloader',

  props: {
    message: String,
    color: String,
    padding: String,
    textColor: String,
  }
}

</script>
