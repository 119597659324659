<template>
  <v-dialog
    :value="show"
    :max-width="$dialog.medium"
    no-click-animation
    persistent
    scrollable
  >
    <close-btn @click="closeDialog()" overflow />

    <v-card>
      <v-card-title>
        <h5 class="font-weight-bold text-h5 secondary--text">Invite Users</h5>
      </v-card-title>
      <v-card-text>
        <v-alert :value="!!status.inviteError" transition="fade" type="error" border="left" text>
          {{ status.inviteError }}
        </v-alert>

        <v-form ref="inviteForm" @submit.prevent="validateForm()">
          <v-textarea
            @keydown.enter.prevent="valueUpdated($event)"
            label="Email Addresses"
            class="field-shadow mb-5"
            hide-details
            outlined
          ></v-textarea>

          <!-- SELECT -->
          <v-row class="mb-5" v-if="selected.length">
            <v-col>
              <v-select
                @input="roleUpdated"
                :items="['client', 'user']"
                class="field-shadow"
                hide-details
                label="Role"
                outlined
                dense
              ></v-select>
            </v-col>
            
            <v-col>
              <v-select
                @input="packageUpdated"
                :items="packages"
                class="field-shadow"
                item-text="name"
                item-value="id"
                label="Package"
                hide-details
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>

          <!-- EMAILS LIST -->
          <v-card v-if="emails.length" outlined>
            <v-simple-table dense outlined>
              <template #default>
                <thead>
                  <tr>
                    <th>
                      <div class="d-flex align-center">
                        <v-checkbox
                          @change="selectedAll"
                          class="mb-2"
                          hide-details
                          dense
                        ></v-checkbox>
                        
                        <span>Email</span>
                      </div>
                    </th>
                    <th>Role</th>
                    <th>Package</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(email, i) in emails" :key="i">
                    <td>
                      <v-checkbox
                        v-model="selected"
                        :label="email"
                        hide-details
                        class="mb-1"
                        :value="i"
                        multiple
                        dense
                      ></v-checkbox>
                    </td>
                    
                    <td width="15%" class="text--secondary caption">
                      <v-select
                        v-model="assignedRoles[i]"
                        :items="['client', 'user']"
                        class="field-shadow"
                        hide-details
                        dense
                      ></v-select>
                    </td>
                    
                    <td width="33%" class="text--secondary caption">
                      <div class="d-flex align-center">
                        <v-select
                          v-if="assignedRoles[i] === 'client'"
                          v-model="assignedPackages[i]"
                          class="field-shadow mr-3"
                          :items="packages"
                          item-text="name"
                          item-value="id"
                          hide-details
                          dense
                        ></v-select>

                        <v-spacer></v-spacer>

                        <v-progress-circular v-if="status.creatingInvite.find(i => i.email == email)" class="float-right" indeterminate color="primary" size="15" width="2"/>
                        
                        <v-tooltip v-if="status.inviteErrors.find(e => e.email.trim() == email.trim())" left>
                          <template #activator="{ on }">
                            <v-icon v-on="on" small color="warning" class="float-right">mdi-alert</v-icon>
                          </template>
                          <span class="caption">{{ status.inviteErrors.find(e => e.email.trim() == email.trim()).error }}</span>
                        </v-tooltip>

                        <v-icon v-if="status.inviteSuccess.find(e => e.trim() == email.trim())" small color="success" class="float-right">mdi-check-circle</v-icon>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-form>
      </v-card-text>
      <template v-if="emails.length">
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            @click="sendInvites()"
            :loading="!!status.creatingInvite.length"
            color="primary"
            small
          >Send Invite</v-btn>

          <v-btn @click="closeDialog()" :disabled="!!status.creatingInvite.length" small text>Cancel</v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/rules'
import { uniq } from 'lodash'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'InviteNewUser',

  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    show: Boolean,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      emails: [],
      selected: [],
      sending: false,
      assignedRoles: [],
      assignedPackages: [],
      data: {
        email: null,
      }
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.users.status,
      packages: state => state.request.packages,
    }),
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('users', [
      'createInvite',
      'inviteUser',
    ]),

    validateForm() {
      if (this.$refs.inviteForm.validate()) {
        Promise.all([
          this.inviteUser(this.data)
        ])
        .then(response => {
          if (!response[0]) {
            this.closeDialog()
          }
        })
      }
    },

    closeDialog() {
      this.$emit('close')
      this.$refs.inviteForm.reset()
      this.$refs.inviteForm.resetValidation()
      this.$store.commit('users/inviteErrorState', false)
    },

    valueUpdated(e) {
      let emails = e.srcElement._value.split(',')
      let validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      this.emails = emails.filter(e => {
        let email = e.trim()
        return email.match(validEmail)
      })

      this.emails = uniq(this.emails)
    },

    selectedAll(e) {
      if (e) {
        for (let i in this.emails) {
          this.selected.push(parseInt(i))
        }
      }
      else {
        this.selected = []
      }
    },

    roleUpdated(e) {
      this.selected.forEach(i => {
        this.$set(this.assignedRoles, parseInt(i), e)
      })
    },
    
    packageUpdated(e) {
      this.selected.forEach(i => {
        this.$set(this.assignedPackages, parseInt(i), e)
      })
    },

    async sendInvites() {
      let promises = []
      this.$store.commit('users/resetInvites')

      for (let i in this.emails) {
        let data = {
          email: this.emails[i].trim(),
          role: this.assignedRoles[i],
          package: this.assignedPackages[i] || '',
        }
       
        promises.push(this.createInvite(data))
      }

      Promise.all(promises)
      .then(
        () => {
          this.$store.dispatch('showSuccess', 'Invitations sent')
        },
        (errors) => {
          if (errors.length) {
            console.log(errors)
          }
        }
      )
      
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *------------------------------------------------------------------------------*/
  mounted() {
    this.$store.dispatch('request/getPackages')
  }
}
</script>