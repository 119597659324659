<template>
  <v-dialog
    :value="show"
    :max-width="$dialog.content"
    no-click-animation
    scrollable
    persistent
  >
    <close-btn @click="closeDialog()" overflow />
    <v-card v-if="user">
      <v-card-title>
        <h5 class="font-weight-bold text-h5">"{{ user.fullName }}" Projects</h5>
      </v-card-title>
      <v-card-text>
        <preloader v-if="getting" message="Checking projects..." />
        
        <v-alert type="info" border="left" class="mb-0" v-if="!projects.length && !getting" text>
          No project found
        </v-alert>

        <v-card v-if="projects.length && !getting" class="box-shadow" outlined>
          <v-list>
            <v-list-item
              :to="{ name: 'Project', params: { id: project.id } }"
              v-for="project in confProjects"
              :key="project.id"
              target="_blank"
              dense
            >
              <v-list-item-title>
                <span class="primary--text">{{ project.name }}</span>
                <span class="grey--text ml-2 caption">created {{ project.createdAt | fromNow }} ago</span>
              </v-list-item-title>
              <v-list-item-icon>
                <v-icon small>mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import db from '@/firebase/init'
import Preloader from './Preloader.vue'
import _orderBy from 'lodash/orderBy'

export default {
  components: { Preloader },
  name: 'UserProjects',

  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    show: Boolean,
    user: Object,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      projects: [],
      getting: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    confProjects: function () {
      return _orderBy(this.projects, 'createdAt', 'desc')
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'user': function (val) {
      if (val) this.getProjects()
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    closeDialog() {
      this.$emit('close')
    },

    getProjects() {
      this.getting = true
      this.projects = []

      if (this.user) {
        db.collection('projects')
        .where('owner', '==', this.user.userid)
        .get()
        .then(snapshot => {
          if (snapshot.size) {
            snapshot.forEach(doc => {
              let data = this.$formatData(doc)
              this.projects.push(data)
            })
  
            this.getting = false
          }
          else {
            this.getting = false
          }
        })
        .catch(error => {
          this.getting = false
          console.log(error.message)
          this.$store.dispatch('showError', error.message)
        })
      }

    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getProjects()
  }
}
</script>